const seaAttractionDict = {
    'アクアトピア': 958,
    'インディ・ジョーンズ®・アドベンチャー： クリスタルスカルの魔宮': 922,
    'ディズニーシー・トランジットスチーマーライン（アメリカンウォーターフロント）': 952,
    'ディズニーシー・エレクトリックレールウェイ（ポートディスカバリー）': 949,
    'キャラバンカルーセル': 964,
    "シンドバッド・ストーリーブック・ヴォヤッジ": 961,
    "ジャスミンのフライングカーペット": 916,
    'ジャンピン・ジェリーフィッシュ': 973,
    "スカットルのスクーター": 970,
    "センター・オブ・ジ・アース": 925,
    'ソアリン：ファンタスティック・フライト': 913,
    "タワー・オブ・テラー": 985,
    'タートル・トーク': 994,
    'トイ・ストーリー・マニア！': 910,
    'ディズニーシー・エレクトリックレールウェイ（アメリカンウォーターフロント）': 940,
    'ディズニーシー・トランジットスチーマーライン（メディテレーニアンハーバー）': 937,
    'ディズニーシー・トランジットスチーマーライン（ロストリバーデルタ）': 943,
    'ニモ＆フレンズ・シーライダー': 997,
    'ビッグシティ・ヴィークル': 955,
    'フォートレス・エクスプロレーション': 988,
    'フォートレス・エクスプロレーション“ザ・レオナルドチャレンジ”': 991,
    "フランダーのフライングフィッシュコースター": 967,
    'ブローフィッシュ・バルーンレース': 976,
    'マジックランプシアター': 934,
    'マーメイドラグーンシアター': 919,
    'レイジングスピリッツ': 982,
    'ワールプール': 979,
    'ヴェネツィアン・ゴンドラ': 946,
    '海底2万マイル': 928,
  };
  export default seaAttractionDict;