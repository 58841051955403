const landAttractionDictEN = {
    'Photo Location: Happy Fair Lab': 853,
    "Alice's Tea Party": 775,
    "\"it's a small world\"": 772,
    "Westernland Shootin' Gallery": 727,
    "Western River Railroad": 718,
    "Omnibus": 709,
    'Pirates of the Carribean': 712,
    'Country Bear Theater': 730,
    "Gadget's Go Coaster": 793,
    'Castle Carrousel': 766,
    "Goofy's Paint 'n' Play House": 799,
    "Cinderella's Fairy Tale Hall": 754,
    'Jungle Cruise: Wildlife Expeditions': 715,
    'Swiss Family Treehouse': 721,
    'Star Tours: The Adventures Continue': 805,
    'Stitch Encounter': 841,
    'Splash Mountain': 742,
    'Space Mountain': 808,
    "Chip 'n Dale's Treehouse": 790,
    'Tom Sawyer Island Rafts': 739,
    "Donald's Boat": 796,
    "Buzz Lightyear's Astro Blasters": 811,
    'Big Thunder Mountain': 736,
    'Beaver Brothers Explorer Canoes': 745,
    "Pinocchio's Daring Journey": 760,
    "Peter Pan's Flight": 748,
    "Pooh's Hunny Hunt": 778,
    "The Happy Ride with Baymax": 844,
    "Penny Arcade": 829,
    "Haunted Mansion": 769,
    "Mickey's PhilharMagic": 757,
    "Minnie's House": 784,
    "Monsters, Inc. Ride & Go Seek!": 823,
    "Roger Rabbit's Car Toon Spin": 781,
    "Snow White's Adventures": 751,
    "Dumbo The Flying Elephant": 763,
    "Enchanted Tale of Beauty and the Beast": 847,
    "Mark Twain Riverboat": 733,
    "The Enchanted Tiki Room: Stitch Presents \"Aloha E Komo Mai!\"": 724,
};
export default landAttractionDictEN;