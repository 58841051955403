const landAttractionDict = {
    '【体験型フォトロケーション】ハッピーフェア・ラボ': 853,
    "アリスのティーパーティー": 775,
    "イッツ・ア・スモールワールド": 772,
    "ウエスタンランド・シューティングギャラリー": 727,
    "ウエスタンリバー鉄道": 718,
    "オムニバス": 709,
    'カリブの海賊': 712,
    'カントリーベア・シアター': 730,
    "ガジェットのゴーコースター": 793,
    'キャッスルカルーセル': 766,
    "グーフィーのペイント＆プレイハウス": 799,
    "シンデレラのフェアリーテイル・ホール": 754,
    'ジャングルクルーズ：ワイルドライフ・エクスペディション': 715,
    'スイスファミリー・ツリーハウス': 721,
    'スター・ツアーズ：ザ・アドベンチャーズ・コンティニュー': 805,
    'スティッチ・エンカウンター': 841,
    'スプラッシュ・マウンテン': 742,
    'スペース・マウンテン': 808,
    "チップとデールのツリーハウス": 790,
    'トムソーヤ島いかだ': 739,
    "ドナルドのボート": 796,
    "バズ・ライトイヤーのアストロブラスター": 811,
    'ビッグサンダー・マウンテン': 736,
    'ビーバーブラザーズのカヌー探険': 745,
    "ピノキオの冒険旅行": 760,
    "ピーターパン空の旅": 748,
    "プーさんのハニーハント": 778,
    "ベイマックスのハッピーライド": 844,
    "ペニーアーケード": 829,
    "ホーンテッドマンション": 769,
    "ミッキーのフィルハーマジック": 757,
    "ミニーの家": 784,
    "モンスターズ・インク“ライド＆ゴーシーク！”": 823,
    "ロジャーラビットのカートゥーンスピン": 781,
    "白雪姫と七人のこびと": 751,
    "空飛ぶダンボ": 763,
    "美女と野獣“魔法のものがたり”": 847,
    "蒸気船マークトウェイン号": 733,
    "魅惑のチキルーム：スティッチ・プレゼンツ“アロハ・エ・コモ・マイ！”": 724,
};
export default landAttractionDict;