const seaAttractionDictEN = {
    'Aquatopia': 958,
    'Indiana Jones® Adventure: Temple of the Crystal Skull': 922,
    'DisneySea Electric Railway': 952,
    'Caravan Carousel': 964,
    "Sindbad's Storybook Voyage": 961,
    "Jasmine's Flying Carpets": 916,
    "Jumpin' Jellyfish": 973,
    "Scuttle's Scooters": 970,
    "Journey to the Center of the Earth": 925,
    'Soaring: Fantastic Flight': 913,
    'Turtle Talk': 994,
    'Tower of Terror': 985,
    'Toy Story Mania!': 910,
    'DisneySea Transit Steamer Line': 937,
    'Nemo & Friends SeaRider': 997,
    'Big City Vehicles': 955,
    'Fortress Explorations': 988,
    'Leonardo Challenge': 991,
    "Flounder's Flying Fish Coaster": 967,
    'Blowfish Balloon Race': 976,
    'The Magic Lamp Theater': 934,
    "Mermaid Lagoon Theater (King Triton's Concert)": 919,
    'Raging Spirits': 982,
    'The Whirlpool': 979,
    'Venetian Gondolas': 946,
    '20,000 Leagues Under the Sea': 928,
  };
  export default seaAttractionDictEN;